import React, { useEffect } from 'react';
import { getScopedI18n, getSurveyLanguage } from 'core/i18n';
import { RouteComponentProps } from 'react-router';
import { logScreen } from 'core/analytics';

interface Props extends RouteComponentProps {}

export const Finish: React.FC<Props> = ({}: Props) => {
  useEffect(() => {
    logScreen('ASSESSMENT_FINISH');
  }, []);

  const t = getScopedI18n('survey_assessment.finish', { locale: getSurveyLanguage() });

  return (
    <div>Thank you for filling in this survey.</div>

    // <div className="assessment_questionaire main container">
    //   <div className="row mt-5">
    //     <h1 className="col">{t('title')}</h1>
    //   </div>
    //   <div className="row mt-3">
    //     <div className="col">
    //       <h5>{t('subtitle')}</h5>
    //       <button
    //         type="button"
    //         className="btn btn-primary btn-lg mt-4"
    //         onClick={() => {
    //           window.location.replace('/finish');
    //         }}
    //       >
    //         {t('button')}
    //       </button>
    //     </div>
    //   </div>
    // </div>
  );
};
